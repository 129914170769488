import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cta from "../components/cta"

import AboutUsImage from "../components/images/aboutUsImage"
import BgPattern from "../components/images/bgPattern"
import MeetOurTeam from "../components/meetOurTeam"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import aboutStyles from "../components/about.module.css"
// CLIENTS
import ClientOne from "../components/images/clientOne"
import ClientTwo from "../components/images/clientTwo"
import ClientThree from "../components/images/clientThree"
import ClientFour from "../components/images/clientFour"
import ClientFive from "../components/images/clientFive"
import PlanetWise from "../components/images/planetWise"
import Sanford from "../components/images/sanford"
import Petstar from "../components/images/petstar"
import InterAM from "../components/images/interam"
import Yama from "../components/images/yama"
import RPFoods from "../components/images/rpfoods"
import Jela from "../components/images/jela"
import TrendMakers from "../components/images/trendmakers"
import Kuii from "../components/images/kuii"
import Beambo from "../components/images/beambo"
import Formations from "../components/images/formations"

const calculateSlides = () => {
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 800
  if (windowWidth <= 576) return 2
  if (windowWidth <= 992) return 3
  return 5
}
let settings = {
  arrows: true,
  dots: true,
  infinite: true,
  slidesToShow: calculateSlides(),
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 1500
}

const About = () => {
  return (
    <Layout>
      <SEO title="About" />

      <div style={{ backgroundColor: "#f1f1f1" }}>
        <Container>
          <Row className={aboutStyles.aboutSection}>
            <Col lg={7} className={aboutStyles.aboutText}>
              <h2><span className={aboutStyles.underlineRight}>About Us</span></h2>
              <p>
                Strategic Alliances, Inc. (SAI), was founded in 1996, and is a professional services, consulting, and business process outsourcing (BPO) company that designs, develops, deploys, and delivers tailored, holistic, and integrated business solutions to worldwide small and midsized product and manufacturing companies desiring to sell and distribute their goods through the largest retail outlets in North America. SAI has a sales & professional services office and 50,000 square foot warehouse in Rogers, AR. SAI also operates a software development and Business Process Outsourcing in Belgrade, Europe.
              </p>
            </Col>
            <Col lg={5}>
              <AboutUsImage />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="separator"></div>

      <Container className={aboutStyles.clientSection}>
        <Row>
          <Col>
            <h1><span className={aboutStyles.underlineCenter}>Our Clients</span></h1>
            <p>
              Strategic Alliances, Inc. (SAI) primary focus is always on your
              business. We are customer driven and client focused. We carefully
              consider the capabilities, business objectives, and strategic
              directions of all stakeholders to ensure we thoroughly understand
              each stakeholders' desired business result.
            </p>
          </Col>
        </Row>
        <Slider {...settings} className={`about-us-slider`}>
          <ClientOne />
          <ClientTwo />
          <ClientThree />
          <ClientFour />
          <ClientFive />
          <Sanford />
          <TrendMakers />
          <Kuii />
          <Beambo />
          <PlanetWise />
          <Petstar />
          <InterAM />
          <RPFoods />
          <Jela />
          <Yama />
          <Formations />
        </Slider>
      </Container>
      <div className="separator"></div>

      <BgPattern>
        <MeetOurTeam />
      </BgPattern>

      <Cta text="Ready to shake hands?" />
    </Layout>
  )
}

export default About
